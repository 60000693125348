/* CARD LOOK */

.card-row {
  display: flex;
  justify-content: center;
}

.card {
  flex-basis: 30%;
  padding: 1rem;
  margin: 1rem;
  /* text-align: center; */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  /* Add transition effect */
}

.card-hoverable:hover {
  transform: translateY(-5px);
  /* Add translateY for hover effect */
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.15);
  /* Update box shadow on hover */
}

.card-img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
}

.card-title {
  font-weight: bold;
}

.card-price {
  font-weight: bold;
}

.prop-det {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.carousel-control-prev,
.carousel-control-next {
  top: 50%;
  transform: translateY(-50%);
}