@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Mulish';
  font-size: 16px;
}

.header {
  background-image: url("../../images/out/banner.jpg");
  background-size: cover;
  background-position: center;
  height: 400px;
  position: relative;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* Updated alignment */
  color: #ffffff;
  padding: 20px;
  /* Added padding for spacing */
}

.border-radius-30px {
  border-radius: 30px;
}

.w18rem {
  width: 18rem;
}

.bg-color {
  background-color: black;
}

.custom-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-image {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
}

.my-max-bold {
  font-weight: 900 !important;
}

.my-gray {
  color: #474747f0;
}
